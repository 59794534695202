<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading"> </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">換領券資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-input label="換領券ID" :fieldValue.sync="formData.coupon_id" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="註冊日期" :fieldValue.sync="formData.create_date" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="換領券名稱" :fieldValue.sync="formData.name" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="訂單ID" :fieldValue.sync="formData.order_id" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="持有人會員姓名" :fieldValue.sync="formData.username" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-input label="到期日" :fieldValue.sync="formData.expiry_date" disabled />
            </v-col>
            <v-col cols="12" md="6">
              <form-select
                label="狀態"
                :fieldValue.sync="formData.coupon_status"
                :options="couponStatusOptions"
                required
                :disabled="isTransferred"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading" v-if="!isTransferred">
        儲存
      </v-btn>
    </v-form>

    <v-card class="my-8">
      <v-card-text>
        <v-card-title class="page-title">轉贈紀錄</v-card-title>
        <datatable
          :tableHeaders="tableHeaders"
          :tableData="tableData"
          :isLoading="tableLoading"
          @options-update="getTransferList()"
          disableShadow
          disablePagination
        >
          <template #item.transfer_status="{ item }">
            <span v-if="item.transfer_status === 'from'">獲取</span>
            <span v-if="item.transfer_status === 'to'">轉贈予</span>
          </template>
        </datatable>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'UserCouponDetail',
  components: {
    FormInput,
    FormSelect,
    Datatable,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    couponStatusOptions() {
      if (this.isTransferred) {
        return [...this.$couponStatusOptions, { text: '已轉贈', value: 'transferred' }]
      }

      return this.$couponStatusOptions;
    }
  },
  data: () => ({
    formData: {
      coupon_id: null,
      create_date: '',
      name: '',
      order_id: null,
      username: '',
      expiry_date: '',
      coupon_status: '',
      user_id: -1,
      from_user_coupon_id: -1,
      transfer_id: -1,
    },

    userId: null,

    tableLoading: false,
    tableData: [],
    tableHeaders: [
      { text: '持有人會員姓名', value: 'username' },
      { text: '日期', value: 'create_date' },
      { text: '狀態', value: 'transfer_status' },
    ],

    isTransferred: false,
  }),
  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetUserCouponById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }
        this.formData.create_date = this.$Formatter.displayCreateDate(data.create_date)
        if (data.coupon_data) {
          this.formData.name = data.coupon_data.name
        }

        if (data.order_data) {
          this.formData.order_id = data.order_data.id
        }

        if (data.user_data) {
          this.formData.username = data.user_data.name
        }

        this.userId = data.user_id

        this.tableLoading = true
        this.getTransferToList()
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'UserCoupon',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'UserCoupon',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async save() {
      if (this.$store.getters.isLoading || this.isTransferred) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }
      this.$store.dispatch('setLoading', true)
      const user = this.getCurrentUserData()
      try {
        await this.$Fetcher.UpdateUserCouponStatus(
          this.formData.coupon_id,
          this.userId,
          this.formData.coupon_status,
          user.id,
          user.token,
        )

        this.$store.dispatch('setDataIsUpdated', false)
        this.$store.dispatch('toggleSnack', {
          type: 'success',
          message: '儲存成功',
          refresh: true,
        })
      } catch (err) {
        this.setAlert({
          title: '儲存失敗',
          message: err,
          type: 'error',
        })
      } finally {
        this.$store.dispatch('setLoading', false)
      }
    },

    async getTransferToList() {
      try {
        const user = this.getCurrentUserData()
        let payload = {
          get_user_data: true,
          user_id: this.formData.user_id,
          from_user_coupon_id: this.id,
        }
        
        const { data } = await this.$Fetcher.GetTransferRecordOfUserCoupon(payload, user.id, user.token)
        this.$common.log('-----------> Transfer TO', data);
        this.isTransferred = true;
        this.formData.coupon_status = 'transferred';
        data.forEach(item => {
          this.tableData.push({
            username: item.user_data ? item.user_data.name : '',
            create_date: item.transfer_date,
            transfer_status: 'to',
          })
        })

        this.getTransferFromList()
      } catch (err) {
        this.$common.error(err)
        this.getTransferFromList()
      }
    },

    async getTransferFromList() {
      if (this.formData.transfer_id > -1 && this.formData.from_user_coupon_id > -1) {
        try {
          const user = this.getCurrentUserData()
          let payload = {
            user_id: this.formData.transfer_id,
            from_user_coupon_id: this.formData.from_user_coupon_id,
            get_transfer_id_user_data: true,
          }
          const { data } = await this.$Fetcher.GetTransferRecordOfUserCoupon(payload, user.id, user.token)
          this.$common.log('-----------> Transfer FROM', data);
          data.forEach(item => {
            this.tableData.push({
              username: item.transfer_user_data ? item.transfer_user_data.name : '',
              create_date: item.transfer_date,
              transfer_status: 'from',
            })
          })
        } catch (err) {
          this.$common.error(err)
        } finally {
          this.tableData.sort((a, b) => {
            const aDate = new Date(a.create_date)
            const bDate = new Date(b.create_date)
            return bDate.getTime() - aDate.getTime()
          })
          this.tableLoading = false
        }
      } else {
        this.tableLoading = false
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
